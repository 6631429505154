import { get } from 'lodash';

// constants
import {
	aboutScholarshipAboutConfig,
	aboutScholarshipApplicationConfig,
	aboutScholarshipContactConfig,
	aboutScholarshipEligibilityConfig,
	aboutScholarshipGeneralConfig,
	aboutUpdatedOnConfig,
	bannerSectionConfig,
	counsellorCtaSectionConfig,
	defaultCoverImage,
	detailBannerSectionConfig,
	modalFormCtaSectionConfig,
	scholarshipFilterSectionConfig,
	scholarshipGroupSectionConfig,
} from './constants';

// utils
import {
	formatLastUpdated,
	generateAboutList,
	generateApplicationList,
	generateContactList,
	generateEligibilityList,
	generateGeneralDetailsList,
	getScholarshipAmount,
	getScholarshipCardData,
} from './util';

export const getScholarshipBannerSection = (title, highlightTitle) => ({
	...bannerSectionConfig,
	title,
	highlightTitle,
});

export const getScholarshipDetailBannerSection = (data) => ({
	...detailBannerSectionConfig,
	title: data.name,
	image: {
		url: get(data, 'universities[0].images.logo.original', null),
		alternativeText: data.name,
	},
	universityId: get(data, 'universities[0]._id'),
});

export const getScholarshipGroupSection = (
	cardList,
	universityId,
	universityName,
	listCount = 6
) => ({
	...scholarshipGroupSectionConfig,
	universityId,
	universityName,
	cardList: cardList.slice(0, listCount).map(getScholarshipCardData),
});

export const getScholarshipWithFilterSection = () => ({
	...scholarshipFilterSectionConfig,
});

export const getContentSections = (data) => {
	const scholarshipAmount = get(data, 'scholarship_amount'),
		generalDetails = {
			university: get(data, 'universities[0]'),
			scholarshipName: data.name,
			currency: scholarshipAmount.currency,
			level: data.level,
			no_of_scholarships: data.no_of_scholarships,
			mergeable: data.mergeable ? 'Yes' : 'No',
			country: data.countries,
			merit: get(data, 'eligibility.type'),
		};

	if (
		scholarshipAmount &&
		scholarshipAmount.waiver_type &&
		scholarshipAmount.type
	) {
		const waiverType = scholarshipAmount.waiver_type,
			type = scholarshipAmount.type,
			amount = scholarshipAmount.discount_amount,
			currency = scholarshipAmount.currency;

		generalDetails.amount = getScholarshipAmount(
			currency,
			amount,
			waiverType,
			type
		);
	}

	const sectionConfigList = [];

	let body = generateGeneralDetailsList(generalDetails);
	if (body)
		sectionConfigList.push({
			...aboutScholarshipGeneralConfig,
			body,
		});

	const eligibilityData = get(data, 'eligibility');
	if (eligibilityData) {
		const exams = [];
		if (get(eligibilityData, 'tests.english_tests.ielts')) exams.push('IELTS');
		if (get(eligibilityData, 'tests.english_tests.toefl')) exams.push('TOEFL');
		if (get(eligibilityData, 'tests.aptitude_tests.gre')) exams.push('GRE');

		body = generateEligibilityList({
			gender: eligibilityData.gender,
			merit: eligibilityData.type,
			program: data.program_keywords,
			country: data.countries,
			score: get(eligibilityData, 'cgpa.score_in_percentage'),
			description: get(eligibilityData, 'tests.description'),
			exams,
		});

		if (body) {
			sectionConfigList.push({ ...aboutScholarshipEligibilityConfig, body });
		}
	}

	body = generateAboutList({
		page_link: data.page_link,
		additional_notes: data.additional_notes,
		description: data.description,
	});
	if (body) {
		sectionConfigList.push({ ...aboutScholarshipAboutConfig, body });
	}

	const contactDetails = data.contact_details;
	let contactSectionIndex;
	if (contactDetails) {
		body = generateContactList({
			contactEmail: contactDetails.email,
			contactPhone: contactDetails.phone_number,
			contactLink: contactDetails.link,
			contactDescription: contactDetails.description,
		});

		if (body) {
			contactSectionIndex = sectionConfigList.push({
				...aboutScholarshipContactConfig,
				body,
			});
		}
	}

	const applicationDetails = data.application;
	if (applicationDetails) {
		body = generateApplicationList({
			applicationLink: applicationDetails.link,
			applicationProcess: applicationDetails.process,
		});

		if (body) {
			sectionConfigList.push({ ...aboutScholarshipApplicationConfig, body });
		}
	}

	if (sectionConfigList.length) {
		sectionConfigList.push({
			...aboutUpdatedOnConfig,
			infoNote: formatLastUpdated(data.modified_date),
		});

		sectionConfigList.splice(
			contactSectionIndex
				? contactSectionIndex - 1
				: Math.floor((sectionConfigList.length - 1) / 2),
			0,
			{ ...counsellorCtaSectionConfig }
		);
	}

	return sectionConfigList;
};

export const getThirdCtaSection = () => modalFormCtaSectionConfig;

export const getMetaData = (scholarshipLogo = defaultCoverImage) => ({
	keywords: {
		name: 'keywords',
		content:
			'Scholarship, aid, financial aid, scholarships for college, financial assistance, mba scholarships, scholarship applications, scholarship applications, post matric scholarship, free scholarships, scholarship for study abroad, Prost grad scholarship, top scholarship, best scholarship, university scholarship, government scholarship',
	},
	'twitter:card': {
		name: 'twitter:card',
		content: 'summary_large_image',
	},
	'og:title': {
		content: 'Top Scholarships | Azent India',
		property: 'og:title',
	},
	'twitter:title': {
		name: 'twitter:title',
		content: 'Top Scholarships | Azent India',
	},
	'og:image': {
		content: scholarshipLogo,
		property: 'og:image',
	},
	'twitter:image': {
		content: scholarshipLogo,
		name: 'og:image',
	},
	'og:description': {
		content:
			'Get the best scholarships with expert application guidance from our extensive collection!',
		property: 'og:description',
	},
	'twitter:description': {
		name: 'twitter:description',
		content:
			'Get the best scholarships with expert application guidance from our extensive collection!',
	},
});

export const getScholarshipDetailsMetaData = (
	scholarshipName,
	scholarshipLogo = defaultCoverImage
) => ({
	keywords: {
		name: 'keywords',
		content: `${scholarshipName}, Study abroad with ${scholarshipName}, study overseas with ${scholarshipName}, overseas study with ${scholarshipName}, best overseas scholarship, study abroad scholarship, Scholarship, aid, financial aid, scholarships for college, financial assistance, mba scholarships, scholarship applications, scholarship applications, post matric scholarship, free scholarships, scholarship for study abroad, Prost grad scholarship, top scholarship, best scholarship, university scholarship, government scholarship`,
	},
	'twitter:card': {
		name: 'twitter:card',
		content: 'summary',
	},
	'og:title': {
		content: `Best scholarship is ${scholarshipName}`,
		property: 'og:title',
	},
	'twitter:title': {
		name: 'twitter:title',
		content: `Best scholarship is ${scholarshipName}`,
	},
	'og:image': {
		content: scholarshipLogo,
		property: 'og:image',
	},
	'twitter:image': {
		content: scholarshipLogo,
		name: 'og:image',
	},
	'og:description': {
		content: `${scholarshipName}, Study abroad with ${scholarshipName}, study overseas with ${scholarshipName}, overseas study with ${scholarshipName}, best overseas scholarship, study abroad scholarship, Scholarship, aid, financial aid, scholarships for college, financial assistance, mba scholarships, scholarship applications, scholarship applications, post matric scholarship, free scholarships, scholarship for study abroad, Prost grad scholarship, top scholarship, best scholarship, university scholarship, government scholarship`,
		property: 'og:description',
	},
	'twitter:description': {
		name: 'twitter:description',
		content: `${scholarshipName}, Study abroad with ${scholarshipName}, study overseas with ${scholarshipName}, overseas study with ${scholarshipName}, best overseas scholarship, study abroad scholarship, Scholarship, aid, financial aid, scholarships for college, financial assistance, mba scholarships, scholarship applications, scholarship applications, post matric scholarship, free scholarships, scholarship for study abroad, Prost grad scholarship, top scholarship, best scholarship, university scholarship, government scholarship`,
	},
});
